<template>
  <div>
    <SectionWrapper
      v-if="!loadingAddress"
      title="Address details"
      description=""
      :is-valid-section="isValidSection"
      :loading="loading"
      @saveSection="setAddress">
      <MapboxInput
        :address="address"
        :address-error="errors"
        :classes="''"
        @inputHandler="inputHandler"
        @selectAddress="selectAddress"
        @clearAddress="clearAddress" />
      <div class="grid grid-cols-6 gap-4 mt-4">
        <div class="col-span-6 md:col-span-3">
          <Listbox v-if="!loadingCountries" key="country" v-model="selectedCountry" as="div">
            <ListboxLabel class="block text-sm font-medium text-gray-700">Country</ListboxLabel>
            <div class="relative mt-1">
              <ListboxButton
                class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm">
                <span class="block truncate text-left">
                  {{ selectedCountry ? selectedCountry.title : 'Select country' }}
                </span>
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ListboxOptions
                  v-if="countries.length > 0"
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ListboxOption
                    v-slot="{ active, selected }"
                    v-for="country in countries"
                    :key="country.id"
                    :value="country"
                    as="template">
                    <li
                      :class="['group relative select-none py-2 pl-3 pr-9 cursor-pointer',
                               active ? 'bg-highlight-600 text-white' : 'text-gray-900']"
                      :data-title="country.title">
                      <span
                        :class="[
                          selected ? 'font-medium' : 'font-normal',
                          'block truncate',
                        ]">
                        {{ country.title }}
                      </span>
                      <span
                        v-if="selected"
                        :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                 active ? 'text-white' : 'text-highlight-600']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
        <div class="col-span-6 md:col-span-3">
          <div>
            <label for="city" class="block text-sm font-medium text-gray-700">City</label>
            <div class="mt-1">
              <input
                id="city"
                v-model="city"
                type="text"
                name="city"
                class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <div>
            <label for="street" class="block text-sm font-medium text-gray-700">Street</label>
            <div class="mt-1">
              <input
                id="street"
                v-model="street"
                type="text"
                name="street"
                class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <div>
            <label for="zipCode" class="block text-sm font-medium text-gray-700">Zip code</label>
            <div class="mt-1">
              <input
                id="zipCode"
                v-model="zipCode"
                type="text"
                name="zipCode"
                class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
    <div v-else class="loading-state flex justify-center py-20 bg-gray-800 bg-opacity-5 rounded-lg">
      <LoadingSpinner outer-class="h-10 h-10 text-water" />
    </div>
  </div>
</template>

<script>
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import MapboxInput from "@/components/UI/MapboxInput/MapboxInput";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { ref, computed } from "vue";
import getCountryId from "@/helpers/getCountryId";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { GET_ADDRESS, SET_ADDRESS } from "@/graphql/address";
import { GET_COUNTRIES } from "@/graphql/products/counties/counties";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "Address",
  components: { SectionWrapper, MapboxInput, LoadingSpinner },
  setup() {
    const store = useStore();

    const isAddressSelected = ref(false);

    const { result, loading: loadingCountries } = useQuery(GET_COUNTRIES);

    const countries = computed(() => result.value?.getCountries);

    const { result: addressData, loading: loadingAddress, onResult } = useQuery(GET_ADDRESS, null, () => ({
      fetchPolicy:  'network-only'
    }));

    const initialAddress = computed(() => addressData.value?.getAddress);

    const selectedCountry = ref(null);

    const schema = yup.object({
      address: yup.string().nullable(),
      countryId: yup.string().required().nullable(),
      city: yup.string().required().nullable(),
      street: yup.string().required().nullable(),
      zipCode: yup.string().required().nullable()
    })

    const { values, errors, setValues, resetForm } = useForm({
      initialValues: {
        address: '',
        countryId: null,
        city: null,
        street: null,
        zipCode: null
      },
      validationSchema: schema
    })

    const { value: address } = useField('address');
    const { value: countryId } = useField('countryId');
    const { value: city } = useField('city');
    const { value: street } = useField('street');
    const { value: zipCode } = useField('zipCode');

    const isSectionDirty = useIsFormDirty();
    const isSectionValid = useIsFormValid();

    const isValidSection = computed(() => isSectionDirty.value && isSectionValid.value);

    onResult(() => {
      if (initialAddress.value) {
        resetForm({
          touched: false,
          values: {
            address: '',
            countryId: initialAddress.value.country_id ?? null,
            city: initialAddress.value.city ?? '',
            street: initialAddress.value.street ?? '',
            zipCode: initialAddress.value.zip_code ?? ''
          }
        });

        if (initialAddress.value?.country_id) {
          selectedCountry.value = countries.value.find(country => country.id === initialAddress.value.country_id);
        }
      }
    });

    const inputHandler = () => {
      isAddressSelected.value = false;
    }

    const clearAddress = () => {
      isAddressSelected.value = false;
      address.value = "";
    };

    const selectAddress = (value) => {
      isAddressSelected.value = true;
      const city = value.context.find((item) => item.id.split(".")[0] === "place").text_en;
      const street = `${value.text_en} ${value.address ?? ''}`;
      const zipCode = value.context.find((item) => item.id.split(".")[0] === "postcode").text_en;
      const countryId = getCountryId(value.context, countries.value);
      selectedCountry.value = countries.value.find(country => country.id === countryId);
      setValues({
        address: street,
        countryId,
        city,
        street,
        zipCode
      })
    };

    const { mutate: setAddress, loading, onDone, onError } = useMutation(SET_ADDRESS, () => ({
      variables: {
        data: {
          city: city.value,
          country_id: countryId.value,
          street: street.value,
          user_id: store.state.auth.user.id,
          zip_code: zipCode.value
        }
      }
    }))

    onDone(() => {
      notify({
        title: "Address successfully saved",
        type: 'success'
      })

      resetForm({
        touched: false,
        values: {
          address: '',
          countryId: values.countryId,
          city: values.city,
          street: values.street,
          zipCode: values.zipCode
        }
      });
    });

    onError((err) => {
      notify({
        title: err.message,
        type: "error"
      });
    });

    return {
      errors,
      address,
      countryId,
      city,
      street,
      zipCode,
      isSectionDirty,
      isSectionValid,
      inputHandler,
      selectAddress,
      clearAddress,
      loadingCountries,
      countries,
      selectedCountry,
      isValidSection,
      setAddress,
      loading,
      loadingAddress
    }
  }
};
</script>

<style scoped></style>
