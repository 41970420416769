import gql from "graphql-tag";

const GET_FINANCE = gql`
  query getFinance {
    getFinance {
      iban
      swift
      vat
      org
    }
  }
`;

const SET_FINANCE = gql`
  mutation setFinance($data: FinanceInput!) {
    setFinance(data: $data) {
      iban
      id
      swift
      vat
      org
    }
  }
`;

export { GET_FINANCE, SET_FINANCE };
