import gql from "graphql-tag";

const SET_ADDRESS = gql`
  mutation setAddress($data: SetAddressInput!) {
    setAddress(data: $data) {
      city
      country_id
      street
      zip_code
    }
  }
`;

const GET_ADDRESS = gql`
  query getAddress {
    getAddress {
      city
      country_id
      street
      zip_code
    }
  }
`;

export { SET_ADDRESS, GET_ADDRESS };
