<template>
  <div class="border rounded-lg mb-4">
    <div class="p-4 md:p-6">
      <div class="section-header">
        <h2 class="text-lg leading-6 font-bold text-gray-800">{{ title }}</h2>
        <p v-if="description" class="mt-1 text-sm text-gray-500 mt-4">{{ description }}.</p>
      </div>
      <div class="action-area mt-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoFooter",
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }

};
</script>
